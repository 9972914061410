// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-co-paintings-js": () => import("./../../../src/pages/co-paintings.js" /* webpackChunkName: "component---src-pages-co-paintings-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-paintings-js": () => import("./../../../src/pages/la-paintings.js" /* webpackChunkName: "component---src-pages-la-paintings-js" */),
  "component---src-pages-ny-paintings-js": () => import("./../../../src/pages/ny-paintings.js" /* webpackChunkName: "component---src-pages-ny-paintings-js" */),
  "component---src-pages-sf-paintings-js": () => import("./../../../src/pages/sf-paintings.js" /* webpackChunkName: "component---src-pages-sf-paintings-js" */),
  "component---src-pages-under-the-stairs-js": () => import("./../../../src/pages/under-the-stairs.js" /* webpackChunkName: "component---src-pages-under-the-stairs-js" */)
}

